<template>
  <ui-card
    v-loading="loading"
    :heading="`${$t('actions.create')} ${$tc('models.department')}`"
  >
    <department-form
      :department="department"
      @submit="handleSubmit"
      @cancel="handleCancel"
    ></department-form>
  </ui-card>
</template>

<script>
import UiCard from '@/components/ui/UiCard';
import DepartmentForm from '../components/DepartmentForm';
import { getBuilding } from '@/app/buildings/api';
import { createDepartment } from '../api';

export default {
  data() {
    return {
      department: {
        name: '',
        description: '',
        building: null
      },
      loading: false
    };
  },

  components: {
    UiCard,
    DepartmentForm
  },

  methods: {
    async handleSubmit() {
      this.loading = true;
      await createDepartment(this.department);
      this.$router.push({ name: 'departments-index' });
      this.loading = false;
    },

    handleCancel() {
      this.$router.back();
    },

    async getBuilding(id) {
      this.loading = true;
      const building = await getBuilding(id);
      this.department.building = building;
      this.loading = false;
    }
  },

  created() {
    if (this.$route.params.buildingId) {
      this.getBuilding(this.$route.params.buildingId);
    }
  },

  mounted() {
    this.$store.commit('changeBreadcrumbs', [
      {name: "models.departments", link: "/app/departments/"},
      {name: "actions.create", link: ""}
    ]);
  },
};
</script>
